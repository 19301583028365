import { gql } from '@urql/core';

export const CREATE_CALLBACK = gql`
  mutation ($input: CallBackInput!) {
    createCallBack(input: $input) {
      name
      via
      __typename
    }
  }
`;
