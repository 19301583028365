import { gql } from '@urql/core';

export const AVAILABLE_CALLBACK_OPTIONS_QUERY = gql`
  query AvailableCallBackOptions {
    availableCallBackOptions {
      date
      times
      __typename
    }
  }
`;
